import React, { useState } from "react";
import "./App.css";

function App() {
  window.scrollTo(0, 0);

  var mode = "prettycss_body_lite";
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    mode = "prettycss_body_dark";
  } else {
    mode = "prettycss_body_lite";
  }

  const [prettycss_page_thime, set_prettycss_page_thime] = useState(mode);

  function prettycss_change_theme(mode) {
    set_prettycss_page_thime(mode);
  }
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "dark" : "light";

      if (newColorScheme === "dark") {
        prettycss_change_theme("prettycss_body_dark");
      } else {
        prettycss_change_theme("prettycss_body_lite");
      }
    });

  return (
    <div id={prettycss_page_thime}>
      <div id="prettycss_header">
        <h1>pretty css</h1>
        <p>
          Below is an illustrative presentation of CSS code that I find <br />
          particularly appealing and beneficial in my development work. The
          <br />
          ensuing snippet encapsulates style rules that I frequently employ to
          <br />
          enhance the visual and structural aspects of web projects.
        </p>
      </div>
      <div className="pretty_css_main_card">
        <div className="pretty_css_center_card">
          {/*  */}
          <div className="flsdhfhj">
            <div className="pretty_css_text_card">
              <div>
                <h1>disable text selection</h1>
                <p>
                  -webkit-user-select: none;
                  <br />
                  -ms-user-select: none;
                  <br />
                  user-select: none;
                  <br />
                </p>
              </div>
            </div>
            <div id="pretty_css_disabled_text_selection">
              <p>you can't selection this text</p>
            </div>
          </div>
          {/*  */}
          <div className="flsdhfhj">
            <div className="pretty_css_text_card">
              <p>
                display: flex;
                <br />
                justify-content: center;
                <br />
                align-items: center;
              </p>
            </div>
            <div id="pretty_css_center_blue">
              <div id="pretty_css_center_red"></div>
            </div>
          </div>

          <div className="flsdhfhj">
            <div className="pretty_css_text_card">
              <p>
                display: grid; <br />
                grid-template-columns: 1fr auto 4fr;
              </p>
            </div>
            <div className="pretty_css_grid">
              <div id="pretty_css_grid_1">
                <h1>1</h1>
              </div>
              <div id="pretty_css_grid_2">
                <h1>2</h1>
              </div>
              <div id="pretty_css_grid_3">
                <h1>3</h1>
              </div>
              <div id="pretty_css_grid_4">
                <h1>4</h1>
              </div>
              <div id="pretty_css_grid_5">
                <h1>5</h1>
              </div>
              <div id="pretty_css_grid_6">
                <h1>6</h1>
              </div>
            </div>
          </div>
          <div className="flsdhfhj">
            <div className="pretty_css_text_card">
              <p>
                I also employ the flexbox layout technique; however, its
                intricate nature presents a challenge in effectively showcasing
                the expansive array of possibilities it encapsulates. So here’s
                a cute picture of a dog.
              </p>
            </div>
            <div id="prettycss_cute_dog_parnt">
              <div id="prettycss_cute_dog"></div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
}

export default App;
