import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import "./Input.css";
import "./Message.css";
import elizabotl from "./elizabot_container.js"; // Import the elizabot module

function ScrollToBottom() {
  const containerRef = useRef(null);
  const inputRef = useRef(null); // Add a reference for the input element

  const [messages_log, set_messages_log] = useState([]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages_log]); // Trigger the effect when messages_log changes

  function elizabot_mobile_historical_processing_user_input() {
    const userInput = inputRef.current.value;

    if (userInput) {
      inputRef.current.value = "";

      const newMessage = { text: userInput, sender: "my" };
      const botResponse = {
        text: elizabotl.start(userInput).toString(),
        sender: "not_my",
      };

      // Update the messages log and then scroll to the bottom
      set_messages_log((prevMessages) => [
        ...prevMessages,
        newMessage,
        botResponse,
      ]);
      scrollToBottom();
    }
  }

  // Listen for Enter key press on the input field
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      elizabot_mobile_historical_processing_user_input();
    }
  };

  return (
    <div id="con">
      <div id="log" ref={containerRef}>
        <div className="not_my_message">
          <p>
            Hi, I'm ELIZA. I was made by Joseph Weizenbaum in 1966 to serve as
            an early example of a computer program that could engage in natural
            language conversations with users, primarily to explore and
            demonstrate the potential of artificial intelligence in
            human-computer interactions.
          </p>
        </div>

        {messages_log.map(function (data, index) {
          if (data.sender === "my") {
            return (
              <div key={index}>
                <div className="my_message">
                  <p>{data.text}</p>
                </div>
              </div>
            );
          }

          if (data.sender === "not_my") {
            return (
              <div className="not_my_message" key={index}>
                <p>{data.text}</p>
              </div>
            );
          }

          return <div key={index}></div>;
        })}
      </div>

      <div id="inputt">
        <div id="elizabot_mobile_historical_text_box">
          <input
            type="text"
            id="elizabot_mobile_historical_text_input"
            ref={inputRef}
            onKeyPress={handleKeyPress}
          />
          <div
            id="elizabot_mobile_historical_text_input_button"
            onClick={elizabot_mobile_historical_processing_user_input}
          >
            <p>&gt;</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScrollToBottom;
