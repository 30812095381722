import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./Index.css";

import Home from "./components/home/App";
import PrettyCss from "./components/pretty_css/App";
import Eliza from "./components/eliza/App";
import LearnJapanese from "./components/learn_japanese/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="prettycss" element={<PrettyCss />} />
      <Route path="eliza" element={<Eliza />} />
      <Route path="learn_japanese" element={<LearnJapanese />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
