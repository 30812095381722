import React, { useState } from "react";
import "./App.css";

var reset_scroll = true;

function App() {
  var elizabot = require("./elizabot.js");
  elizabot.start();

  function eliza_say(question) {
    let reply = elizabot.reply(question);
    return reply;
  }

  var mode = "home_body_lite";
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    mode = "elizabot_page_thime_dark";
  } else {
    mode = "elizabot_page_thime_lite";
  }

  const [chat_bot_page_thime, set_chat_bot_page_thime] = useState(mode);

  function chatbot_change_theme(mode) {
    set_chat_bot_page_thime(mode);
  }
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "dark" : "light";

      if (newColorScheme === "dark") {
        chatbot_change_theme("elizabot_page_thime_dark");
      } else {
        chatbot_change_theme("elizabot_page_thime_lite");
      }
    });

  // reset scroll if the user is visiting for the first time
  if (reset_scroll === true) {
    window.scrollTo(0, 0);
  }
  reset_scroll = false;

  const [historical_chat_log, set_historical_chat_log] = useState([]);

  var historical_is_processing = false;

  function historical_processing_user_input() {
    if (historical_is_processing === false) {
      // get user input from historical_text_input and store it in userInput
      let userInput = document.getElementById("historical_text_input").value;

      if (userInput) {
        set_historical_chat_log((prevChatLog) => [
          ...prevChatLog,
          userInput.toString(),
        ]);

        document.getElementById("historical_text_input").value = "";

        historical_is_processing = true;

        var div = document.getElementById("historical_bot_chat_spinner");
        div.style.display = "block";

        setTimeout(() => {
          set_historical_chat_log((prevChatLog) => [
            ...prevChatLog,
            eliza_say(userInput.toString()).toString(),
          ]);

          var div = document.getElementById("historical_bot_chat_spinner");
          div.style.display = "none";

          historical_is_processing = false;
        }, 1500);
      }
    }
  }

  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      historical_processing_user_input();
    }
  });

  return (
    <div id={chat_bot_page_thime}>
      <div id="elizabot_desktop_main">
        <div id="historical_bots_name_and_description">
          <h1>ELIZA (1966)</h1>
          {/*  */}
          <div id="historical_description">
            <h1>ELIZA (1966): A Comprehensive Description</h1>
            <br />
            <p>
              <strong>Background and Purpose:</strong>
            </p>
            <ul>
              <li>
                ELIZA was developed in 1966 by Joseph Weizenbaum at MIT's
                Artificial Intelligence Laboratory.
              </li>
              <li>
                The purpose of ELIZA was to demonstrate the potential of natural
                language processing and human-computer interactions.
              </li>
            </ul>
            <br />
            <p>
              <strong>Core Principles:</strong>
            </p>
            <ol>
              <li>
                <strong>Pattern Matching:</strong>
                <ul>
                  <li>
                    ELIZA used simple pattern matching techniques to engage in a
                    conversation that simulated a Rogerian psychotherapist.
                  </li>
                  <li>
                    It recognized and responded to specific patterns of text
                    input and provided pre-programmed responses based on those
                    patterns.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <strong>Rogerian Psychotherapy Simulation:</strong>
                <ul>
                  <li>
                    ELIZA emulated a Rogerian psychotherapist, a human-centered
                    therapy approach developed by psychologist Carl Rogers.
                  </li>
                  <li>
                    The program was designed to reflect the therapeutic
                    technique of reflecting the patient's statements back to
                    them to encourage self-exploration and understanding.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <strong>Parsing and Transformation:</strong>
                <ul>
                  <li>
                    ELIZA used parsing rules to transform user input into
                    questions, statements, or prompts that resembled empathetic
                    or therapeutic responses.
                  </li>
                  <li>
                    It employed keyword-based substitution to generate responses
                    that appeared to demonstrate understanding and empathy.
                  </li>
                </ul>
              </li>
            </ol>

            <br />
            <p>
              <strong>Interaction and Conversational Style:</strong>
            </p>
            <ul>
              <li>
                ELIZA engaged users in a conversational manner, making them
                believe they were interacting with a real psychotherapist.
              </li>
              <li>
                The program encouraged users to express their feelings and
                thoughts by asking open-ended questions, reflecting statements,
                and offering encouragement.
              </li>
            </ul>
            <br />
            <p>
              <strong>Limitations and Recognizable Features:</strong>
            </p>
            <ul>
              <li>
                ELIZA had several limitations, such as a lack of true
                understanding or consciousness. It operated based on programmed
                patterns and responses without genuine comprehension.
              </li>
              <li>
                Users quickly realized that ELIZA could be easily misled, and
                its responses were based on simple pattern matching rather than
                true understanding.
              </li>
            </ul>
            <br />
            <p>
              <strong>Impact and Legacy:</strong>
            </p>
            <ul>
              <li>
                ELIZA had a significant impact on the field of artificial
                intelligence and human-computer interaction.
              </li>
              <li>
                It sparked public interest and awareness of the potential of
                computers to engage in human-like interactions and
                conversations.
              </li>
              <li>
                ELIZA influenced subsequent research and development in natural
                language processing, leading to more advanced conversational
                agents and chatbots in the decades that followed.
              </li>
            </ul>
          </div>
          {/*  */}
        </div>
        <div id="historical_chat_main_box">
          <div id="historical_chat">
            <div id="historical_chat_left">
              <div className="historical_chat_left_bot_highelite">
                <p>eliza</p>
              </div>

              {/* <div className="historical_chat_left_bot">
                <p>a.l.i.c.e</p>
              </div>

              <div className="historical_chat_left_bot">
                <p>parry</p>
              </div> */}
            </div>
            <div id="historical_chat_rith">
              <div id="historical_chat_log">
                {historical_chat_log.map(function (data, index) {
                  if (index % 2 === 0) {
                    return (
                      <div className="hfsjkdfh">
                        <p className="historical_me">{data}</p>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <p className="historical_bot_chat">{data}</p>
                      </div>
                    );
                  }
                })}

                <div id="historical_bot_chat_spinner">
                  <div id="historical_spinner"></div>
                </div>
              </div>
              <div id="historical_text_box">
                <input type="text" id="historical_text_input" />
                <div
                  id="historical_text_input_button"
                  onClick={() => historical_processing_user_input()}
                >
                  <p>&gt;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
