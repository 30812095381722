import React, { useState } from "react";
// import MetaTags from "react-meta-tags";
import "./App.css";

function generate_a_random_list(min, max, the_list) {
  var end_list = [];
  var arr = [];
  while (arr.length < 5) {
    var r = Math.floor(Math.random() * (max - min) + min);
    if (arr.indexOf(r) === -1) arr.push(r);
  }
  for (let index = 0; index < arr.length; index++) {
    end_list.push(the_list[arr[index]]);
  }
  return end_list;
}

function App() {
  window.scrollTo(0, 0);
  
  var mode = "lite";
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    mode = "dark";
  } else {
    mode = "lite";
  }
  const [theme, settheme] = useState(mode);
  function change_theme(mode) {
    settheme(mode);
  }
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      const newColorScheme = event.matches ? "dark" : "light";

      if (newColorScheme === "dark") {
        change_theme("dark");
      } else {
        change_theme("lite");
      }
    });

  var hiragana = [
    ["する", "to do"],
    ["しない", "do not"],
    ["します", "to do(formal)"],
    ["できる", "can do"],
    ["しよう", "lets do"],
  ];
  // var hiragana = [
  //   ["111","aaa"],
  //   ["222","bbb"],
  //   ["333","ccc"],
  //   ["444","ddd"],
  //   ["555","eee"],
  //   ["wwww", "wwww"],
  // ];

  var min = 0;
  // var max = 5;
  var max = hiragana.length;

  // const [hiragana, setHiragana] = useState(
  //   generate_a_random_list(min, max, all_hiragana_and_romaji)
  // );

  const [right_answer_and_hiragana, setRight_answer] = useState([
    // Math.floor(Math.random() * (hiragana.length - min) + min),
    Math.floor(Math.random() * 4),
    generate_a_random_list(min, max, hiragana),
  ]);

  function get_if_the_answer_is_correct(numb) {
    if (
      right_answer_and_hiragana[1][numb] ===
      right_answer_and_hiragana[1][right_answer_and_hiragana[0]]
    ) {
      setRight_answer([
        Math.floor(Math.random() * (hiragana.length - min) + min),
        generate_a_random_list(min, max, hiragana),
      ]);
      console.log("good");
    } else {
      console.log("bad");
    }
  }

  function one() {
    get_if_the_answer_is_correct(0);
  }
  function two() {
    get_if_the_answer_is_correct(1);
  }
  function tree() {
    get_if_the_answer_is_correct(2);
  }
  function fore() {
    get_if_the_answer_is_correct(3);
  }
  function five() {
    get_if_the_answer_is_correct(4);
  }

  console.log(right_answer_and_hiragana);

  return (
    
    <div className={theme}>
      {/* <MetaTags>
        <meta name="theme-color" content="#fff" />
      </MetaTags> */}
      <div className="main_canvas">
        <div>
          <div className="right_answer">
            <h1>
              {right_answer_and_hiragana[1][right_answer_and_hiragana[0]][1]}
            </h1>
          </div>

          <div className="test_page_button_ary">
            <div onClick={one} className="test_page_button">
              <p>{right_answer_and_hiragana[1][0][0]}</p>
            </div>
            <div onClick={two} className="test_page_button">
              <p>{right_answer_and_hiragana[1][1][0]}</p>
            </div>
            <div onClick={tree} className="test_page_button">
              <p>{right_answer_and_hiragana[1][2][0]}</p>
            </div>
            <div onClick={fore} className="test_page_button">
              <p>{right_answer_and_hiragana[1][3][0]}</p>
            </div>
            <div onClick={five} className="test_page_button">
              <p>{right_answer_and_hiragana[1][4][0]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
