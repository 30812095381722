import React from "react";
import "./App.css";

import Desktop from "./desktop/App";
import Mobile from "./mobile/App";

function App() {
  return (
    <div>
      <div id="elizabotMobile">
        <Mobile />
      </div>

      <div id="elizabotDesktop">
        <Desktop />
      </div>
    </div>
  );
}

export default App;
